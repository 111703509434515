import React from "react";

const UserAction = ({ user, onDelete, onImpersonate }) => {
  return (
    <div className="flex items-center justify-center">
      <a
        href={`/users/${user.id}/edit`}
        className="bg-other-turquoise text-black font-bold px-4 py-2 rounded hover:bg-green-600 mr-2 text-center"
      >
        Edit
      </a>
      <button
        onClick={() => onImpersonate(user.id)}
        className="bg-stat-blue text-black font-bold px-4 py-2 rounded hover:bg-blue-500 mr-2"
      >
        Impersonate
      </button>
      <button
        onClick={() => onDelete(user.id)}
        className="bg-red-500 text-black font-bold px-4 py-2 rounded hover:bg-red-700"
      >
        Delete
      </button>
    </div>
  );
};

export default UserAction;
