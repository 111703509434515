import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserTable from "./userTable";
import api from "./api";

const Index = ({ users: initialUsers }) => {
  const [users, setUsers] = useState(initialUsers);

  const handleDelete = (userId) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      api
        .delete(`/users/${userId}`)
        .then((response) => {
          setUsers(response.data);
          toast.success("User deleted successfully");
        })
        .catch((error) => {
          console.error("Error deleting user:", error);
          toast.error("Failed to delete user");
        });
    }
  };

  const handleImpersonate = (userId) => {
    api
      .get(`/users/${userId}/masquerade_token`)
      .then((response) => {
        window.location.href = response.data.path;
      })
      .catch((error) => {
        console.error("Error impersonating user:", error);
        toast.error("Failed to impersonate user");
      });
  };

  const sortedUsers = users.length
    ? users.sort((a, b) => a.email.localeCompare(b.email))
    : [];

  return (
    <div className="p-2">
      <ToastContainer />
      <div className="flex justify-between mb-4">
        <h1 className="text-2xl font-bold text-left w-1/3">Users Admin</h1>
        <div className="w-1/3 text-right">
          <a
            href="/users/invite"
            className="bg-black text-white font-medium p-2.5 rounded hover:bg-gray-800 inline-block"
          >
            Invite New User
          </a>
        </div>
      </div>
      {users.length === 0 ? (
        <div className="flex justify-center items-center h-64">
          <div className="text-center">
            <p className="text-xl font-semibold mb-2">
              Looks like you haven't added any users yet!
            </p>
            <p>
              <a href="/users/invite" className="text-blue-500 hover:underline">
                Invite a User
              </a>
            </p>
          </div>
        </div>
      ) : (
        <UserTable
          users={sortedUsers}
          onDelete={handleDelete}
          onImpersonate={handleImpersonate}
        />
      )}
    </div>
  );
};

export default Index;
